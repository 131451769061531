.App {
    text-align: center;
  }
  .test{
    color: #0e5b71;
  }
  .App-logo {
    height: 20vmin;
    pointer-events: none;
  }
  @media print {
      iframe {
          display: none;
      }
      .print-link {
          display: block;
      }
  }
  @media screen {
      .print-link {
          display: none;
      }
  }
  @media print {
      .doc-navigation {
          display: none;
      }
  }
    
  @media (prefers-reduced-motion: no-preference) {
    /*.App-logo {
      animation: App-logo-spin infinite 20s linear;
      transition: transform 1s;
      transform: rotate(15deg);
    }*/
    @keyframes rotate {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(15deg);
      }
    } 
  
    .App-logo {
      animation-name: rotate;
      animation-duration: 7s; /* adjust as needed */
      animation-delay: 5s; /* adjust as needed */
      animation-fill-mode: forwards; /* keeps the logo at the final state of the animation */
      animation-iteration-count: infinite; /* makes the animation repeat infinitely */
      animation-direction: alternate; /* makes the animation go back to the start */
    }
  }
  
  .App-header {
    background-color: #6478a2;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  .App-menubar {
      background-color: #282c34;
      min-height: 5vh;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center; 
      font-size: calc(10px + 1vmin);
      color: white;
      /*padding: 10px; *//* Optional: Add some padding for better spacing */
    }
  .App-link {
    color: #61dafb;
    margin-right: 10px;
    text-decoration: none; /* Optional: Remove underline from links */
  }
  .App-link svg {
      margin-right: 5px;
  }
  
  .App-link:hover .tooltip {
      visibility: visible;
      opacity: 1;
  }
  
  .tooltip {
      visibility: hidden;
      background-color: black;
      color: #fff;
      text-align: center;
      border-radius: 5px;
      padding: 5px;
      position: absolute;
      z-index: 1;
      opacity: 0;
      transition: opacity 0.3s;
      margin-top: -5px;
      margin-left: 5px;
  }
  
  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  .links-container {
      display: flex;
      justify-content: space-between; /* adjust as needed */
    }
    
    .lesson-container:hover {
      box-shadow: 0px 12px 20px 0px rgba(66, 60, 60, 0.25);
    }
    
    .App-text {
      color: #000;
      text-decoration: none;
      font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
      text-align: left;
      font-size: 14px;
    }
  
    .File-text {
      color: #000;
      text-decoration: none;
      font-size: calc(10px);
      text-align: left;
    }
    h1 {
      text-align: center;
    }
    h2 {
      font-size: 18px !important;
    }
    img { 
      pointer-events: none;
    }
    .lesson-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around; /* This will distribute the boxes evenly with equal space around them */
      list-style-type: none;
      padding: 0;
    }
    .form-fieldlist {
      display: flex;
      flex-direction: column; /* Stack items vertically */
      align-items: center; /* Center items horizontally */
      list-style-type: none;
      padding: 0;
      margin: 0;
      gap: 0;
   }
   .form-fieldlist > li {
      margin: 5px; /* Remove any default margin */
      padding: 5px; /* Ensure padding isn't causing separation */
      text-align: left;
      width: 50%; /* Make the boxes as wide as the parent container */
      max-width: 50%;
  }
   .form-container {
      background-color: #f0f0f0;
      margin: 0;
      padding: 5px;
      box-shadow: 0px 8px 8px 0px rgba(0,0,0,0.2);
      transition: 0.3s;
      border-radius: 5px;
  }
  .form-fields {
      background-color: #f0f0f0;
      margin: 0;
      padding: 5px;
      border-radius: 5px;
  }
  /* override bootstrap default buttton css */
  .custom-button {
      background-color: hsl(104.38deg 56.44% 40.46%) !important; /* Match the SVG icon color */
      border-color: #61dafb !important; /* Match the SVG icon color */
      color: #fff !important; /* Text color */
      min-height: 40px;
      position: relative;
      z-index: 1;
  }
    
  .custom-button:disabled {
      background-color: #c7d6d6 !important; /* Light grey background for disabled state */
      border-color: #61dafb !important; /* Darker grey border for disabled state */
      color: #fff !important; /* Grey text color for disabled state */
  }
  .custom-button:hover {
      background-color: #4db8e6 !important; /* Slightly darker shade for hover effect */
      border-color: #4db8e6; /* Slightly darker shade for hover effect */
  }
  .custom-button:disabled:hover {
      background-color: hsl(0, 0%, 80%) !important; /* Slightly lighter grey for hover effect on disabled state */
      border-color: hsl(0, 0%, 60%) !important; /* Slightly darker grey border for hover effect on disabled state */
  }
  
  .input-wrapper {
      position: relative !important;
  }
  
  .input-icon {
      position: absolute !important;
      left: 10px; /* Adjust as needed */
      top: 50%;
      transform: translateY(-50%);
      z-index: 10;
  }
  
  .custom-input {
      padding-left: 50px !important; /* Adjust to make space for the icon */
  }
    .lesson-container {
      background-color: #f0f0f0;
      margin: 10px 5px;
      padding: 5px;
      box-shadow: 0px 8px 8px 0px rgba(0,0,0,0.2);
      transition: 0.3s;
      border-radius: 5px;
      flex: 1 0 calc(33.33% - 10px); /* This calculates the width as one third of the container's width minus the total horizontal margin (10px) */
      max-width: calc(33.33% - 10px); /* Same calculation for max-width */
      box-sizing: unset;
    }
    
    .filetext-container {
      background-color: #f0f0f0;
      margin: 10px 5px 0px 30px;
      padding: 5px;
      box-shadow: 0px 8px 8px 0px rgba(0,0,0,0.2);
      transition: 0.3s;
      border-radius: 5px;
      flex: 1 0 calc(90% - 10px); /* This calculates the width as one third of the container's width minus the total horizontal margin (10px) */
      max-width: calc(90% - 10px); /* Same calculation for max-width */
    }
    input[type="text"],
    input[type="email"],
    input[type="phone"],
    textarea {
        border: none;
        /* Remove the default border */
        border-bottom: 1px solid #282c34;
        /* Add a bottom border */
        outline: none;
        /* Remove the outline on focus */
        width: 100%;
        /* Optional: Make the input take the full width of its container */
        padding: 8px 0;
        /* Optional: Add some padding for better spacing */
        box-sizing: border-box;
        /* Ensure padding doesn't affect the width */
    }
  
    input[type="text"]:focus,
    input[type="email"]:focus,
    input[type="phone"]:focus,
    textarea:focus {
        border-bottom-color: #007bff;
        /* Change the bottom border color on focus */
    }
  
    @keyframes App-logo-spin {
        from {
        transform: rotate(0deg);
        }
        to {
        transform: rotate(360deg);
        }
    }
    
    [data-amplify-authenticator] {
        --amplify-components-authenticator-router-box-shadow: 0 0 16px var(--amplify-colors-overlay-10);
        --amplify-components-authenticator-router-border-width: 0;
        --amplify-components-authenticator-form-padding: var(--amplify-space-medium) var(--amplify-space-xl) var(--amplify-space-xl);
        --amplify-components-button-primary-background-color: hsl(104.38deg 56.44% 40.46%);
        --amplify-components-fieldcontrol-focus-box-shadow: 0 0 0 2px hsl(104.38deg 56.44% 40.46%);
        --amplify-components-tabs-item-active-border-color: hsl(104.38deg 56.44% 40.46%);
        --amplify-components-tabs-item-color: var(--amplify-colors-neutral-80);
        --amplify-components-tabs-item-active-color: var(--amplify-colors-purple-100);
        --amplify-components-button-link-color: var(--amplify-colors-purple-80);
        border-radius: 100px;
        }
    
        [data-amplify-authenticator] [data-amplify-container] {
        border-radius: 45px; /* Adjust the value as needed */
        overflow: hidden; /* Ensure children elements respect the border radius */
    }
  
    #logoPhrase
    {
        position: relative;
        top:-20px;
        float:right;
        height:auto;
        width:auto;
        padding:0px 15px 5px 0px;
        font:italic bold 12px/20px arial,sans-serif;
        letter-spacing:2px;
        word-spacing:2px;
        color: #AFEEEE;
    
    }
    #logo
    {
        position:relative;
        top:0px;
        left:5px;
        height:auto;
        width:auto;
        padding:0px;
        font:italic bold 38px/40px arial,sans-serif;
        letter-spacing:2px;
        word-spacing:2px;
        color: #F0F8FF;
    }
  